import * as React from 'react';
import styled from 'styled-components';

import { JobDetails } from '../../components/JobDetails';
import { Body as BaseBody } from '../../components/Typography';
import backendImage from '../../content/env-work.jpg';

const Body = styled(BaseBody)({ maxWidth: 1000 });

const JobDetail = () => (
	<JobDetails
		name="Back-End Developer"
		image={backendImage}
		color="#bf26b6"
		area="💻 Web Development"
		type="⏱ Full Time"
		location="📍 Stuttgart (Arena2036)"
		model="On Site & Remote"
		about={
			<Body>
				We are looking for a Back-end Developer who will be responsible for the server side of our
				hub technology platform. You will work closely with our engineers to ensure system
				consistency and improve functionality.
				<br />
				<br />
				You should be able to develop and maintain functional and stable applications to meet our
				company’s needs. Therefore you have to possess excellent programming skills and a passion
				for developing applications or improving existing ones.
			</Body>
		}
		responsibilities={[
			'Take part in the entire application lifecycle, focusing on coding and debugging',
			'Write quality code to develop functional applications',
			'Develop and maintain back-end functions to optimize performance',
			'Debug applications and detect and fix errors',
			'Work together with Front-end Developers to integrate UI elements with server side logic',
			'Implement modern technologies to improve legacy applications',
			'Collect, analyze and address technical and design requirements',
			'Help to create reusable code and libraries for future use',
			'Train and support internal teams',
			'Interact with developers, designers and system administrators to identify new features',
			'Stay informed about emerging technologies',
		]}
		requirements={[
			'Experience of working on Back-end Developer position',
			'Solid experience with programming languages like JavaScript and Java',
			'Excellent understanding of the entire development process (design, development and deployment) and application lifecycle',
			'Strong analytical and time management skills',
			'Good teamwork skills with a problem-solving attitude',
			'BSc degree in Computer Science or other relevant area',
		]}
		qualification={[
			'Enthusiasm for new technologies',
			'Analytical thinking',
			'Ability to abstract',
			'Ability to work independently',
			'General enthusiasm for IoT',
		]}
		offer={[
			'Family-friendly working conditions through flexible working hours',
			'The greatest possible flexibility in your choice of work location - whether in bright, modern Arena2036 or in mobile work or home offices',
			'Extensive social benefits (e.g. various special vacations and allowances)',
			'Continuous training and development opportunities through annual employee appraisals',
			'High-quality individual work equipment',
			'A subsidy for local public transport',
			'Extensive workplace health promotion measures, e.g. fruit basket, coffee, drinks, etc.',
			'Finally, a dynamic working environment with flat hierarchies and a lot of team spirit',
		]}
	/>
);

export default JobDetail;
